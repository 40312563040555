import {
  ComponentType,
  useEffect,
  useMemo
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  ThunkDispatch,
  UnknownAction
} from '@reduxjs/toolkit';
import {
  useConcreteSocket
} from '@shared/api';
import {
  type ChannelMessage,
  clearMessage,
  mapMessage,
  selectMessagesInChannel,
  setMessage,
  useMessagesSelector,
} from '@entities/messages';
import {
  selectSessionKey,
  selectShortProfile,
  useSessionSelector
} from '@entities/session';
import {
  MessageModal
} from '@widgets/message-modal';
import {
  withSocket
} from './with-socket';

export const withMessagesModal = <T extends object>(WrappedComponent: ComponentType<T>) =>
  withSocket((props: T) => {
    const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();
    const {
      subscribe,
      connectSocket,
      disconnectSocket,
      isConnected
    } = useConcreteSocket(
      process.env.REACT_APP_OMEGA_WS_GENERAL_CHANNEL ?? ''
    );

    const sessionKey = useSessionSelector(selectSessionKey);
    const shortProfile = useSessionSelector(selectShortProfile);
    const messages: Maybe<Array<ChannelMessage>> = useMessagesSelector(
      selectMessagesInChannel(process.env.REACT_APP_OMEGA_WS_GENERAL_CHANNEL ?? '')
    );

    const messageToShow: Maybe<ChannelMessage> = useMemo(() => {
      return messages ? messages[0] : null;
    }, [messages]);

    useEffect(() => {
      if (sessionKey && shortProfile?.partyId && !isConnected) {
        const url: URL = new URL(process.env.REACT_APP_OMEGA_WS ?? '');
        url.searchParams.append('channel', process.env.REACT_APP_OMEGA_WS_GENERAL_CHANNEL ?? '');
        url.searchParams.append('id', shortProfile.partyId);
        url.searchParams.append('sessionKey', sessionKey);
        url.searchParams.append('language', 'en');

        connectSocket(url.toString());
      }
    }, [sessionKey, shortProfile, isConnected, connectSocket]);

    useEffect(() => {
      if (isConnected) {
        const subscription = subscribe((data: any) => {
          const mappedMessage: ChannelMessage = mapMessage(data.data);
          dispatch(
            setMessage({
              channelId: process.env.REACT_APP_OMEGA_WS_GENERAL_CHANNEL ?? '',
              message: mappedMessage
            })
          );
        });
        return () => {
          subscription();
        };
      }
    }, [
      isConnected,
      subscribe,
      dispatch
    ]);

    useEffect(() => {
      if (isConnected && !shortProfile)
        disconnectSocket();
    }, [
      isConnected,
      shortProfile,
      disconnectSocket
    ]);

    const handleClose = (): void => {
      dispatch(clearMessage({
        channelId: process.env.REACT_APP_OMEGA_WS_GENERAL_CHANNEL ?? ''
      }));
    };

    return (
      <>
        <WrappedComponent {...props} />
        <MessageModal
          open={!!messageToShow}
          onClose={handleClose}
          message={messageToShow}
        />
      </>
    );
  });
